import ahoy from 'ahoy.js'
import { buildAuthorizationHeader, getToken } from 'utilities/token'

export const onClientEntry = () => {
  const token = getToken()
  let headers = {}
  if (token) {
    headers['Authorization'] = buildAuthorizationHeader(token)
  }

  ahoy.configure({
    headers: headers,
    urlPrefix: process.env.GATSBY_CONSUMER_API_URL,
  })
  ahoy.start()
}
