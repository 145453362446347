module.exports = [{
      plugin: require('/srv/thanx-signup-ui/node_modules/gatsby-plugin-heap/gatsby-browser.js'),
      options: {"plugins":[],"appId":"1889914156","enableOnDevMode":true},
    },{
      plugin: require('/srv/thanx-signup-ui/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://f64dff092b1e49a9a06d160b99a1ad5a@sentry.io/1499727","environment":"production","enabled":true},
    },{
      plugin: require('/srv/thanx-signup-ui/node_modules/gatsby-plugin-styletron/gatsby-browser.js'),
      options: {"plugins":[],"prefix":"st-"},
    },{
      plugin: require('/srv/thanx-signup-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
