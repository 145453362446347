// @flow

export const TOKEN_KEY = 'thanx-consumer-api-token'

export const setToken = (token: ?string) => {
  if (token) localStorage.setItem(TOKEN_KEY, token)
}

export const getToken = (): ?string => localStorage.getItem(TOKEN_KEY)

export function buildAuthorizationHeader(token: string) {
  return `Bearer ${token}`
}
